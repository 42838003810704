export const white = '#FFFFFF';
export const black = '#061621';
export const focus = '#019EE2';

export const gray = {
  dark3: '#21313C',
  dark2: '#3D4F58',
  dark1: '#5D6C74',
  base: '#89979B',
  light1: '#B8C4C2',
  light2: '#E7EEEC',
  light3: '#F9FBFA',
} as const;

export const green = {
  dark3: '#0B3B35',
  dark2: '#116149',
  dark1: '#09804C',
  base: '#13AA52',
  light2: '#C3E7CA',
  light3: '#E4F4E4',
} as const;

export const blue = {
  dark3: '#0D324F',
  dark2: '#1A567E',
  base: '#007CAD',
  light1: '#9DD0E7',
  light2: '#C5E4F2',
  light3: '#E1F2F6',
} as const;

export const yellow = {
  dark3: '#543E07',
  dark2: '#86681D',
  base: '#FFDD49',
  light2: '#FEF2C8',
  light3: '#FEF7E3',
} as const;

export const red = {
  dark3: '#570B08',
  dark2: '#8F221B',
  dark1: '#B1371F',
  base: '#CF4A22',
  light2: '#F9D3C5',
  light3: '#FCEBE2',
} as const;

/**
 *
 * uiColors exports colors used before the brand refresh. For an export of the latest colors, use `palette` instead.
 *
 * Refer to the [upgrade guide](https://github.com/mongodb/leafygreen-ui/blob/main/packages/palette/UPGRADE.md) for additional information.
 *
 * @deprecated
 * @public
 */
const uiColors = {
  white,
  black,
  focus,
  gray,
  green,
  blue,
  yellow,
  red,
};

export default uiColors;
